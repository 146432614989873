import { template as template_61147b61c97346f48f9106fed7bde735 } from "@ember/template-compiler";
const WelcomeHeader = template_61147b61c97346f48f9106fed7bde735(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
