import { template as template_161287ae3eed4f1692aec4e8cca1d291 } from "@ember/template-compiler";
const FKControlMenuContainer = template_161287ae3eed4f1692aec4e8cca1d291(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
